@font-face {
  font-family: 'Rubik-Black';
  src: url('/fonts/Rubik/Rubik-Black.ttf') format('truetype');
  font-weight: 900;
  font-display: swap;
}

@font-face {
  font-family: 'Adjusted Rubik-Regular';
  size-adjust: 104.94%;
  ascent-override: 86%;
  src: url('/fonts/Rubik/Rubik-Regular.ttf') format('truetype');
  font-weight: 900;
  font-display: swap;
}

@font-face {
  font-family: 'Adjusted Rubik-SemiBold';
  size-adjust: 104.94%;
  ascent-override: 86%;
  src: url('/fonts/Rubik/Rubik-Semi-Bold.ttf') format('truetype');
  font-weight: 900;
  font-display: swap;
}

@font-face {
  font-family: 'Rubik-SemiBold';
  src: url('/fonts/Rubik/Rubik-Semi-Bold.ttf') format('truetype');
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: 'Rubik-Regular';
  src: url('/fonts/Rubik/Rubik-Regular.ttf') format('truetype');
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: 'Rubik-Light';
  src: url('/fonts/Rubik/Rubik-Light.ttf') format('truetype');
  font-weight: 300;
  font-display: swap;
}
@font-face {
  font-family: 'SFPro-Regular';
  src: url('/fonts/SFPro/SFPro-Regular.ttf') format('truetype');
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: 'SFPro-SemiBold';
  src: url('/fonts/SFPro/SFPro-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-display: swap;
}
@font-face {
  font-family: 'ChangaOne-Regular';
  src: url('/fonts/ChangaOne/ChangaOne-Regular.ttf') format('truetype');
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: 'Roboto-Regular';
  src: url('/fonts/Roboto/Roboto-Regular.ttf') format('truetype');
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: 'Roboto-Medium';
  src: url('/fonts/Roboto/Roboto-Medium.ttf') format('truetype');
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: 'Roboto-Bold';
  src: url('/fonts/Roboto/Roboto-Bold.ttf') format('truetype');
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: 'Roboto-Light';
  src: url('/fonts/Roboto/Roboto-Light.ttf') format('truetype');
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto-Regular';
  src: url('/fonts/Roboto/Roboto-Regular.ttf') format('truetype');
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: 'Roboto-Bold';
  src: url('/fonts/Roboto/Roboto-Bold.ttf') format('truetype');
  font-weight: 600;
  font-display: swap;
}

html,
body {
  font-family: Rubik-Regular, sans-serif;
  color: #161616;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: #0f62fe;
}

ol, ul {
  list-style: revert;
  padding: 0 1rem;
}

h1 {
  font-family: Rubik-SemiBold, sans-serif;
  font-size: 2rem;
  font-weight: 600;
  line-height: 2.5rem;
}

p, input, button, .button {
  font-family: 'Rubik-Regular', sans-serif;
  font-size: 0.875rem;
  line-height: 1.125rem;
}

a.button {
  line-height: 2rem;
}

p {
  letter-spacing: 0.16px;
  margin: 0 0 1em;
}

label {
  font-family: 'Rubik-Regular', sans-serif;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1rem;
}

strong {
  font-family: 'Rubik-SemiBold';
}

ol {
  padding-inline-start: 40px;
}

p:first-child {
  margin-top: 0;
}

p:last-child {
  margin-bottom: 0;
}

img {
  max-width: 100%;
}
